import './App.css';
import './style/custom.css'
import Portofolio from './components/Portofolio'
import Skill from './components/Skill'
import Intro from './components/Intro'
import MyProject from './components/MyProject'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className='container'>
      <div className='row p-2 min-vh-100 justify-content-center align-items-center'>
        <div className='col-12'>
        {/* <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Intro />} />
              <Route path="skill" element={<Skill />} />
              <Route path="portofolio" element={<Portofolio />} />
            </Route>
          </Routes>
        </BrowserRouter> */}
          <BrowserRouter>
          <div className='row bg-dark rounded p-2'>
                <div className='col-12'>
                    <Routes>
                        <Route index element={<Intro />} />
                        <Route path="skill" element={<Skill />} />
                        <Route path="portofolio" element={<Portofolio />} />
                        <Route path="my-project" element={<MyProject />} />
                    </Routes>
                    {/* <Route path="*" element={<NoPage />} /> */}
                    <Link to="/">
                      <button className="btn btn-dark">Intro</button>
                    </Link>
                    <Link to="/skill">
                      <button className="btn btn-dark">Skill</button>
                    </Link>
                    <Link to="/portofolio">
                      <button className="btn btn-dark">Portofolio</button>
                    </Link>
                    <Link to="/my-project">
                      <button className="btn btn-dark">My Project</button>
                    </Link>
                </div>
            </div>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);