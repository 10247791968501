import { TypeAnimation } from 'react-type-animation';
import Avatar from '../assets/profile/gua.jpg'
import React, { useState } from 'react';
import '../style/intro.css'
import { useFloating } from '@floating-ui/react-dom';
import { Modal, Button } from 'react-bootstrap';
import FormSendEmail from './FormSendEmail';

const Intro = () => {
    useFloating({
        placement: 'right-start',
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <div className="container mt-2">
            <div className="row align-items-center p-5 rounded-top">
                <div className="col-md-6 p-3">
                    <h1>Arifa Muhammad S</h1>
                    <TypeAnimation
                    sequence={[
                            'Saya Seorang Front-end Developer',
                            1000,
                            'Saya Seorang Back-end Developer',
                            1000,
                            'Saya Seorang Konten Kreator',
                            1000
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ fontSize: '1.5em', display: 'inline-block' }}
                        repeat={Infinity}
                    />
                    <p className="text-secondary">
                        Jika anda mencari seorang Software Engineering, anda menemukan salah satu kandidat yang mungkin akan cocok dengan perusahaan anda.
                    </p>

                    <button className="btn btn-outline-light" onClick={handleShow}>Hubungi Saya</button>
                </div>
                <div className="col-md-6 text-center">
                    <img src={Avatar} className="rounded w-75 img-custom" alt=""/>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header className='bg-dark' closeButton>
                <Modal.Title>Kirim Email</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark'>
                    <FormSendEmail handleClose={handleClose} />
                </Modal.Body>
                <Modal.Footer className='bg-dark'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    );
  };
  
  export default Intro;