import React from 'react';

const Portfolio = () => {
  const projects = [
    { id: 1, title: 'Digital Alliance', description: 'Sebagai IT Developer', image: './assets/work/da.png' },
    { id: 2, title: 'Portal Gaming ID', description: 'Sebagai Copywriter', image: './assets/work/pgid.png' },
    { id: 3, title: 'Promosindo Niaga Indonesia', description: 'Sebagai Web Developer', image: './assets/work/promosindo.jpg' },
    // Add more projects as needed
  ];
  
  const projects2 = [
    { id: 1, title: 'Space One', description: 'Sebagai Web Developer', image: './assets/work/spaceone.png' },
    { id: 2, title: 'Rain Game Indonesia', description: 'Sebagai Konten Kreator', image: './assets/work/raingame.png' },
    { id: 3, title: 'PT SDD', description: 'Sebagai Software Engineering', image: './assets/work/sdd.jpg' },
    // Add more projects as needed
  ];

  return (
    <div className="container mt-2">
      <div className="row">
        {projects.map((project) => (
          <div className="col-md-4 mb-4" key={project.id}>
            <div className="card h-100 d-flex flex-column align-items-center text-center">
              <img src={project.image} className="card-img-top w-75" alt={project.title} />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        {projects2.map((project) => (
          <div className="col-md-4 mb-4" key={project.id}>
            <div className="card h-100 d-flex flex-column align-items-center text-center">
              <img src={project.image} className="card-img-top w-75" alt={project.title} />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
