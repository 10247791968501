import React from 'react';

const Skill = () => {
  const skillLogo = [
    { id: 1, title: 'Angular', description: 'Description for project one', image: './assets/logo/angular.svg' },
    { id: 2, title: 'HTML5', description: 'Description for project two', image: './assets/logo/html5.png' },
    { id: 3, title: 'JavaScript', description: 'Description for project three', image: './assets/logo/JavaScript-logo.png' },
    // Add more projects as needed
  ];

  const skill2 = [
    { id: 1, title: 'ReactJs', description: 'Description for project two', image: './assets/logo/react.png' },
    // Add more projects as needed
  ];

  return (
    <div className="container mt-2">
      <div className="row">
        {skillLogo.map((project) => (
          <div className="col-md-4 mb-4" key={project.id}>
            <div className="card h-100 d-flex flex-column align-items-center">
              <img src={project.image} className="card-img-top w-50" alt={project.title} />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        {skill2.map((project) => (
          <div className="col-md-4 mb-4" key={project.id}>
            <div className="card h-100 d-flex flex-column align-items-center">
              <img src={project.image} className="card-img-top w-75" alt={project.title} />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skill;
