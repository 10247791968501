import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';

const FormSendEmail = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    noTelp: '',
    message: '',
  });

  const [status, setStatus] = useState({
    message: '',
    type: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://admin.arifamuhsy.com/api/sendEmail', formData);
      setStatus({
        message: response.message,
        type: 'success',
      });
      handleClose();
    } catch (error) {
      setStatus({
        message: 'Failed to send email.',
        type: 'danger',
      });
    }

    setFormData({
      name: '',
      noTelp: '',
      message: '',
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {status.message && (
        <Alert variant={status.type} onClose={() => setStatus({ message: '', type: '' })} dismissible>
          {status.message}
        </Alert>
      )}
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Nama Anda"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formNoTelp">
        <Form.Label>Telepon</Form.Label>
        <Form.Control
          type="number"
          name="noTelp"
          placeholder="08123456789"
          value={formData.noTelp}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formMessage">
        <Form.Label>Pesan</Form.Label>
        <Form.Control
          as="textarea"
          name="message"
          placeholder="Kami tertarik dengan portofolio Anda!"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="mt-4">
        Send Email
      </Button>
    </Form>
  );
};

export default FormSendEmail;
