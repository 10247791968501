import React from 'react';

const MyProject = () => {
  const projects = [
    { id: 1, title: 'Arishop', description: 'Online Shop', image: './assets/my-project/pc.ico', link: 'https://arishop.arifamuhsy.com/' },
    { id: 2, title: 'Backend Arishop', description: 'Admin Online Shop', image: './assets/my-project/pc.ico', link: 'https://admin-store.arifamuhsy.com/' },
    { id: 3, title: 'Soon', description: 'Soon Description', image: './assets/my-project/soon.png', link: '' },
    // Add more projects as needed
  ];

  return (
    <div className="container mt-2">
      <div className="row">
        {projects.map((project) => (
          <div className="col-md-4 mb-4" key={project.id}>
            <a href={project.link} target="_blank" rel="noreferrer" className="text-decoration-none">
              <div className="card h-100 d-flex flex-column align-items-center text-center">
                <img src={project.image} className="card-img-top w-75" alt={project.title} />
                <div className="card-body">
                  <h5 className="card-title">{project.title}</h5>
                  <p className="card-text">{project.description}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyProject;
